import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import AllLinks from "./AllLinks";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class AdvertisementsReach extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            advDetails: [],
            isLoading: true

        }
    }

    componentDidMount() {
        this.getCampaignDetails()
    }
    getCampaignDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getCampaignDetails', { headers: headers })
            .then(function (response) {
                //   console.log(response);
                if (response.data.status == true) {
                    self.setState({ advDetails: response.data.campaign, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, advDetails } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }
        const b = advDetails.map((i, idx) => {
            var a = new Date(i.updatedAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        const endAdvDate = advDetails.map((i, idx) => {
            var end = new Date(i.endDate)
            var updatedEndDate = end.getDate() + "-" + parseInt(end.getMonth() + 1) + "-" + end.getFullYear();
            return updatedEndDate;
        })
        const statusNew = advDetails.map((i, idx) => {
            var a = (i.status).charAt(0).toUpperCase() + (i.status).slice(1)
            return a;
        })
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    <AllLinks />
                    <div className="mt-3 active-add-h-text"> Advertisement Reach (Last 90 days)</div>
                    {advDetails.length === 0 ? <center><h4>No history.</h4></center> :
                        <div class="mt-3 tableFixHead" >
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr>
                                        <th scope="col" className="th-heading" style={{ width: "54%" }}>Campain Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Updated On</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>End Date</th>
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>Status</th>
                                        <th scope="col" className="th-heading" style={{ width: "6%" }}>Views</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {advDetails.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text">{data.campaignName}</td>
                                            <td className="td-text">{b[i]}</td>
                                            <td className="td-text">{endAdvDate[i]}</td>
                                            <td className="td-text">{statusNew[i]}</td>
                                            <td className="td-text">{data.numberOfViews}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div >
        );
    };
}