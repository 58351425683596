import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import addimage from '../images/addimage.svg';
import moment from "moment-timezone";
import './Advertisement.css';
import sceemimage from '../images/sceem-logo.svg';
import { Edit } from './EditJson';
import fileupload from '../images/file-upload-svgrepo-com.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import { enqueueSnackbar } from "notistack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class PublishAdvertisement extends Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef()
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            imageUrl: "",
            image: "",
            campaignName: "",
            type: "",
            timingType: "",
            clicks: "",
            views: "",
            description: "",
            startDate: "",
            endDate: "",
            clickAmount: "",
            viewAmount: "",
            clicksData: [],
            viewData: [],
            timesOfAdvertisement: "",
            campaignUrl: "",
            campaignPaymentType: "",
            advertiserName: "",
            advertiserEmail: "",
            advertiserNumber: "",
            designation: "",
            url: "",
            advTypes: [],
            advType: "",
            selectedAdvType: '',
            bannerImg: '',
            paidOrFree: "",
            payment: "",
            adv: Edit[0],
            publish: true,
            totalAmount: "0",
            save: false,
            isLoading: true,
            clicksData: [],
            viewsData: [],
            home: false

        }
    }
    onChangePaymentType = (event) => {
        const campaignPaymentType = event.target.value;
        console.log("Selected payment type:", campaignPaymentType); // Debugging log
        this.setState({ campaignPaymentType }, () => {
            const { startDate, endDate, campaignName, clicksData, viewsData } = this.state;

            if (campaignPaymentType === "Free") {
                const calculatedEndDate = this.calculateEndDate(startDate);
                this.setState({ endDate: calculatedEndDate, clicks: this.state.clicks, views: this.state.views, totalAmount: 0 });
            } else {
                // Reset endDate for Paid
                const firstClickValue = clicksData[0]?.clicks || 0;
                const firstViewsValue = viewsData[0]?.views || 0;
                this.setState({ endDate: null, clicks: firstClickValue, views: firstViewsValue }, this.calculateCost);
            }
        });
    };
    getAdvViewsAndClicks = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAdvViewsAndClicks', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        clicksData: response.data.clicksData,
                        viewsData: response.data.viewsData
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    handleViewChange = (e) => {
        const value = e.target.value;
        if (this.state.campaignPaymentType === "Free") {
            this.setState({ views: 100 });
        } else {
            const parsedValue = parseInt(value, 10);
            if (!isNaN(parsedValue) && parsedValue > 0) {
                this.setState({ views: parsedValue }, this.calculateCost);
            }
        }
    };
    handleClickChange = (e) => {
        const value = e.target.value;
        if (this.state.campaignPaymentType === "Free") {
            this.setState({ clicks: 0 });
        } else {
            const parsedValue = parseInt(value, 10);
            if (!isNaN(parsedValue) && parsedValue > 0) {
                this.setState({ clicks: parsedValue }, this.calculateCost);
            }
        }
    };
    calculateCost = () => {
        const { campaignPaymentType, startDate, endDate, clicks } = this.state;
        let months = 0;

        // Calculate the number of months between startDate and endDate
        if (campaignPaymentType === "Paid" && startDate && endDate) {
            const startMonth = startDate.getMonth();
            const startYear = startDate.getFullYear();
            const endMonth = endDate.getMonth();
            const endYear = endDate.getFullYear();

            months = (endYear - startYear) * 12 + (endMonth - startMonth);
        } else if (campaignPaymentType === "Free") {
            months = 0; // Fixed for Free payment type
        }

        const baseCost = 250 * months;
        const clicksCost = clicks > 0 ? clicks * 25 : 0;

        const totalAmount = baseCost + clicksCost;
        this.setState({ totalAmount: totalAmount }); // Update totalAmount in state
        return totalAmount; // Return totalAmount if needed
    };
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onChangeAdv = (e) => {
        this.setState({ selectedAdvType: e.target.value });
    }
    fileHandler = (e) => {
        this.setState({ image: (e.target.files[0]) })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        //  console.log(this.state)
    }
    // changeStartDate(date) {
    //     this.setState({ startDate: date })
    // }
    // changeEndDate(date) {
    //     this.setState({ endDate: date })
    // }
    calculateEndDate = (startDate) => {
        // if (!startDate || isNaN(startDate.getTime())) return null; // Check if startDate is valid
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 90); // Adding 90 days for Free
        return endDate;
    };
    changeStartDate = (date) => {
        if (!date || isNaN(date.getTime())) return;
        this.setState({ startDate: date }, () => {
            if (this.state.campaignPaymentType === "Free") {
                const calculatedEndDate = this.calculateEndDate(date);
                this.setState({ endDate: calculatedEndDate }, this.calculateCost);
            } else {
                this.calculateCost();
            }
        });
    };
    changeEndDate = (date) => {
        if (!date || isNaN(date.getTime())) return;
        this.setState({ endDate: date }, this.calculateCost);
    };
    onChangePaymentType = (event) => {
        const campaignPaymentType = event.target.value;
        console.log("Selected payment type:", campaignPaymentType); // Debugging log
        this.setState({ campaignPaymentType }, () => {
            const { startDate, endDate, campaignName, clicksData, viewsData } = this.state;

            if (campaignPaymentType === "Free") {
                const calculatedEndDate = this.calculateEndDate(startDate);
                this.setState({ endDate: calculatedEndDate, clicks: this.state.clicks, views: this.state.views, totalAmount: 0 });
                console.log("payment free", calculatedEndDate)
            } else {
                // Reset endDate for Paid
                const firstClickValue = clicksData[0]?.clicks || 0;
                const firstViewsValue = viewsData[0]?.views || 0;
                this.setState({ endDate: endDate, clicks: firstClickValue, views: firstViewsValue }, this.calculateCost);

                console.log("payment", this.calculateCost, endDate)
            }
        });
    };
    handleViewChange = (e) => {
        const value = e.target.value;
        if (this.state.campaignPaymentType === "Free") {
            this.setState({ views: 100 });
        } else {
            const parsedValue = parseInt(value, 10);
            if (!isNaN(parsedValue) && parsedValue > 0) {
                this.setState({ views: parsedValue }, this.calculateCost);
            }
        }
    };
    handleClickChange = (e) => {
        const value = e.target.value;
        if (this.state.campaignPaymentType === "Free") {
            this.setState({ clicks: 0 });
        } else {
            const parsedValue = parseInt(value, 10);
            if (!isNaN(parsedValue) && parsedValue > 0) {
                this.setState({ clicks: parsedValue }, this.calculateCost);
            }
        }
    };
    // calculateCost = () => {
    //     const { campaignPaymentType, startDate, endDate, clicks } = this.state;
    //     let months = 0;

    //     // Calculate the number of months between startDate and endDate
    //     if (campaignPaymentType === "Paid" && startDate && endDate) {
    //         const startMonth = startDate.getMonth();
    //         const startYear = startDate.getFullYear();
    //         const endMonth = endDate.getMonth();
    //         const endYear = endDate.getFullYear();

    //         months = (endYear - startYear) * 12 + (endMonth - startMonth);
    //     } else if (campaignPaymentType === "Free") {
    //         months = 0; // Fixed for Free payment type
    //     }

    //     const baseCost = 250 * months;
    //     const clicksCost = clicks > 0 ? clicks * 25 : 0;

    //     const totalAmount = baseCost + clicksCost;
    //     console.log("t", totalAmount)
    //     this.setState({ totalAmount: totalAmount }); // Update totalAmount in state
    //     return totalAmount; // Return totalAmount if needed
    // };
    calculateCost = () => {
        const { campaignPaymentType, startDate, endDate, clicks, views } = this.state;

        // Check if startDate and endDate are valid Date objects or convert them from string if necessary
        const validStartDate = startDate instanceof Date ? startDate : new Date(startDate);
        const validEndDate = endDate instanceof Date ? endDate : new Date(endDate);

        let months = 0;

        // Calculate the number of months between startDate and endDate for Paid campaigns
        if (campaignPaymentType === "Paid" && validStartDate && validEndDate) {
            const startMonth = validStartDate.getMonth();
            const startYear = validStartDate.getFullYear();
            const endMonth = validEndDate.getMonth();
            const endYear = validEndDate.getFullYear();

            // Calculate the months difference
            months = (endYear - startYear) * 12 + (endMonth - startMonth);
        } else if (campaignPaymentType === "Free") {
            months = 0; // Fixed for Free payment type
        }

        // Calculate cost based on the number of months and clicks
        const baseCost = views * months;
        const clicksCost = clicks > 0 ? clicks * 25 : 0;

        const totalAmount = baseCost + clicksCost;

        console.log("Total Cost:", totalAmount);
        this.setState({ totalAmount: totalAmount }); // Update totalAmount in state

        return totalAmount; // Return totalAmount if needed
    };
    componentDidMount = () => {
        this.getCampaignById();
        this.getAdvViewsAndClicks();
        this.getAllAdvertismentTypes();
    }
    getAllAdvertismentTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllAdvertismentTypes', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    self.setState({ advTypes: response.data.advTypes })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getCampaignById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "advertisementId": this.props.match.params.id
        }
        var self = this
        axios.post(config.userUrl + 'user/getAdvertisementById', payload, { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    // console.log("success!!");
                    self.setState({
                        campaignName: response.data.advertisement[0].campaignName,
                        campaignPaymentType: response.data.advertisement[0].advertiserPaymentType,
                        campaignUrl: response.data.advertisement[0].campaignUrl,
                        description: response.data.advertisement[0].description,
                        startDate: response.data.advertisement[0].startDate,
                        endDate: response.data.advertisement[0].endDate,
                        image: response.data.advertisement[0].imageUrl,
                        timesOfAdvertisement: response.data.advertisement[0].timesOfAdvertisement,
                        type: response.data.advertisement[0].type,
                        advertiserName: response.data.advertisement[0].advertiserName,
                        advertiserEmail: response.data.advertisement[0].advertiserEmail,
                        advertiserNumber: response.data.advertisement[0].advertiserNumber,
                        designation: response.data.advertisement[0].designation,
                        bannerImg: response.data.advertisement[0].bannerImg,
                        advType: response.data.advertisement[0].advType,
                        selectedAdvType: response.data.advertisement[0].advType,
                        timingType: response.data.advertisement[0].timingType,
                        clicks: response.data.advertisement[0].clicks,
                        views: response.data.advertisement[0].views,
                        clickAmount: response.data.advertisement[0].clickAmount,
                        viewAmount: response.data.advertisement[0].viewAmount,
                        totalAmount: response.data.advertisement[0].amount,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    // publishAdvertisement = (e) => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + this.state.token,
    //         "applicationType": "web"
    //     }
    //     var payload = {
    //         "advertisementId":this.props.match.params.id,
    //         "type": this.state.type,
    //         "timesOfAdvertisement": this.state.timesOfAdvertisement,
    //         "advertiserPaymentType": this.state.campaignPaymentType,
    //         "description": this.state.description,
    //         "startDate": this.state.startDate,
    //         "endDate": this.state.endDate,
    //         "campaignUrl":this.state.campaignUrl,
    //         "imageUrl":this.state.imageUrl,
    //         "campaignName": this.state.campaignName,
    //         "advertiserName": this.state.advertiserName,
    //         "advertiserNumber": this.state.advertiserNumber,
    //         "advertiserEmail": this.state.advertiserEmail
    //     }
    //     console.log("payload", payload)
    //     var self = this;
    //     axios.post(config.userUrl + 'user/publishedAdvertisementByAdmin', payload, { headers: headers })
    //         .then(function (response) {
    //             console.log("response", response)
    //             if (response.data.status === true) {
    //                 console.log(response, "publishedAdvertisementByAdmin  created successfully!!")
    //                 self.setState({ home: true })
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("payload", payload)
    //             console.log(error);
    //             document.getElementById('error').innerText = error
    //         });
    // }
    publishAdvertisement = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "advertisementId": this.props.match.params.id,
            "campaignName": this.state.campaignName,
            "type": this.state.type,
            "imageUrl": this.state.image,
            "description": this.state.description,
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "bannerImg": this.state.bannerImg,
            "advType": parseInt(this.state.advType),
            "campaignUrl": this.state.campaignUrl,
            "campaignPaymentType": this.state.campaignPaymentType,
            "advertiserName": this.state.advertiserName,
            "advertiserEmail": this.state.advertiserEmail,
            "advertiserNumber": this.state.advertiserNumber,
            "designation": this.state.designation,
            // "clicks": this.state.campaignPaymentType == "Free" ? 0 : parseInt(this.state.clicks),
            // "views": this.state.campaignPaymentType == "Free" ? 100 : parseInt(this.state.views),
            // "amount": (this.state.campaignPaymentType == "Free" ? 0 : 250) + (parseInt(this.state.clicks) * 25),
            "clicks": this.state.campaignPaymentType == "Free" ? 0 : parseInt(this.state.clicks),
            "views": this.state.campaignPaymentType == "Free" ? 100 : parseInt(this.state.views),
            "amount": parseInt(this.state.totalAmount),
            "timingType": this.state.timingType
        }
        //  console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/createCampaign', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        home: true
                    })
                    //    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    handleImage = async (e) => {
        //  console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await this.uploadImage(e.target.files[0]);
            if (res) {
                //  console.log("res", res)
                this.setState({ image: res })
            }
        }
    }
    handleLogoImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            //  console.log('Selected file:', file);
            //  console.log('File size in bytes:', file.size, 64 * 1024, file.size > (64 * 1024));
            let size = ((file.size / 1024).toFixed(2));
            console.log('File type:', file.type);
            const maxSizeInKB = 64;
            if (file.size > maxSizeInKB * 1024) {
                enqueueSnackbar(`Image size is ${size} KB. It should be less than or equal to ${maxSizeInKB} KB.`, { variant: 'error' });
                return;
            }
            const res = await this.uploadImage(file);
            if (res) {
                this.setState({ image: res });
                console.log("res", res)
            }
        } else {
            //     console.warn('No file selected');
        }
    }
    handleBannerImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            let size = ((file.size / 1024).toFixed(2));
            const maxSizeInKB = 270;
            //  console.log('Selected file:', file);
            //  console.log('File size in bytes:', file.size, 64 * 1024, file.size > (64 * 1024));
            if (file.size > maxSizeInKB * 1024) {
                enqueueSnackbar(`Image size is ${size}. It should be less than or equal to ${maxSizeInKB} KB.`, { variant: 'error' });
                return;
            }
            const res = await this.uploadBannerImage(file);
            if (res) {
                this.setState({ bannerImg: res });
                console.log("resbanner", res)
            }
        } else {
            // console.warn('No file selected');
        }
    }
    render() {
        const { startDate, endDate, isLoading, home, type, selectedAdvType, image, imgRef, bannerImg, advTypes, clicksData, viewsData } = this.state
        const maxEndDate = new Date(startDate);
        maxEndDate.setMonth(maxEndDate.getMonth() + 12);
        const maxFreeEndDate = new Date(startDate);
        // maxFreeEndDate.setMonth(maxFreeEndDate.getMonth() + 3);
        maxFreeEndDate.setDate(maxFreeEndDate.getDate() + 90);
        var a = new Date(startDate)
        var start = parseInt(a.getMonth() + 1) + "/" + a.getDate() + "/" + a.getFullYear();
        var b = new Date(endDate)
        var endD = parseInt(b.getMonth() + 1) + "/" + b.getDate() + "/" + b.getFullYear();

        const selectedStartDate = new Date(start)
        const selectedEndDate = new Date(endD)
        // let selectedStartDate;
        // let selectedEndDate;
        // console.log("start", startDate)
        const getText = (id) => {
            const item = advTypes.find(txt => txt.id === id);
            return item ? item.advType : "";
        }
        if (isLoading) {
            return <div className="App"></div>;
        }
        if (home) {
            return <Redirect to="/advertisement" />
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <Card style={{ width: "80%" }}><br />
                        <h5 style={{ marginLeft: "1.2rem" }}>Create Campaign</h5><br />
                        <Form style={{ marginLeft: "1.2rem", width: "70%" }} onSubmit={this.handleSubmit}>
                            <div class="row" >
                                <div class="col-md-9">
                                    <label className="ml-1 mt-1 poll-date" style={{ fontSize: "14px" }}>Product/Promotion Banner<span className="red">*</span></label><br />
                                    <div style={{ height: "120px", textAlign: "center", objectFit: "contain", border: "1px solid black", borderRadius: "10px" }}>
                                        <label htmlFor="upload-button-banner">
                                            {bannerImg != "" ? (
                                                <img src={bannerImg} style={{ marginTop: "4px", marginBottom: "4px", width: "240px", height: "110px" }} />
                                            ) : (
                                                <>
                                                    <img src={fileupload} style={{ marginTop: "2.5rem", cursor: "pointer", width: "29px" }} />

                                                </>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            id="upload-button-banner"
                                            name="bannerImg"
                                            onChange={(e) => this.handleBannerImage(e)}
                                        />
                                    </div>

                                </div>
                                <div className="col-md-3">
                                    <label className="poll-date">Upload Logo<span className="red">*</span></label><br />
                                    <div style={{ height: "120px", textAlign: "center", objectFit: "contain", border: "1px solid black", borderRadius: "10px" }}>
                                        <label htmlFor="upload-button-logo">
                                            {image != "" ? (
                                                <img src={image} style={{ marginTop: "5px", marginBottom: "5px", width: "100px", height: "100px" }} />
                                            ) : (
                                                <>
                                                    <img src={fileupload} style={{ marginTop: "2.5rem", cursor: "pointer", width: "29px" }} />

                                                </>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            id="upload-button-logo"
                                            name="image"
                                            onChange={(e) => this.handleLogoImage(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <label className="poll-date">Campaign Name<span className="red">*</span></label>
                            <input type="text"
                                className="web-font form-control"
                                aria-describedby="text"
                                name="campaignName"
                                placeholder="Campaign Name"
                                value={this.state.campaignName}
                                onChange={this.onChange}
                            /><br />
                            <label className="poll-date">URL<span className="red">*</span></label>
                            <div className="form-group">
                                <input type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    placeholder="Campaign Url"
                                    name="campaignUrl"
                                    value={this.state.campaignUrl}
                                    onChange={this.onChange}
                                />
                            </div>
                            <label className="poll-date">Description<span className="red">*</span></label>
                            <div className="form-group">
                                <textarea type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    rows="5"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.onChange}
                                />
                            </div><br />
                            <div class="row" >
                                <div class="col-md-6">
                                    <label className="poll-date">Business Type<span className="red">*</span></label>
                                    <select value={this.state.type} name="type" onChange={this.onChange} class="web-font form-control">
                                        <option value="">Select</option>
                                        <option value="Commercial">Commercial</option>
                                        <option value="Non-commercial">Non-Commercial</option>
                                    </select>
                                </div>

                                <div class="col-md-6">
                                    <label className="poll-date">Adv Type<span className="red">*</span></label>
                                    <select name="advType" onChange={this.onChangeAdv} className="web-font form-control">
                                        <option value={this.state.selectedAdvType ? this.state.selectedAdvType : ""}>{this.state.selectedAdvType ? getText(this.state.selectedAdvType) : "Select adv type"}</option>
                                        {this.state.advTypes.map(data => (
                                            <option key={data.id} value={data.id}>{data.advType}</option>
                                        ))}
                                    </select>
                                </div>
                            </div><br />
                            <div className="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Paid / Free</label>
                                    <select value={this.state.campaignPaymentType} name="campaignPaymentType" onChange={this.onChangePaymentType} class="web-font form-control">
                                      <option value={this.state.campaignPaymentType}>{this.state.campaignPaymentType ? this.state.campaignPaymentType : "Select"}</option>
                                        <option value={"Free"}>Free (Max 3M)</option>
                                        <option value={"Paid"}>Paid (Max 12M)</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Timing Type<span className="red">*</span></label>
                                    <div className="form-group">
                                        <select
                                            name="timingType"
                                            className="form-control web-font"
                                            value={this.state.timingType}
                                            onChange={this.onChange}
                                        >
                                            <option value="">Select Timing type</option>
                                            <option value={"Omni time"}>Omni time</option>
                                            <option value={"Prime time"}>Prime time</option>
                                        </select>
                                    </div>
                                </div>
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Start Date<span className="red">*</span></label>
                                    <div className="form-group">
                                        {/* <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedStartDate}
                                            minDate={new Date(Date.now())}
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => this.changeStartDate(date)}
                                            filterDate={(date) => date.getDay() === 1}
                                        /> */}
                                        <DatePicker
                                            name="startDate"
                                            className="web-font form-control form-bg"
                                            dateFormat="dd/MM/yyyy"
                                            selected={selectedStartDate}
                                            minDate={new Date()}
                                            onChange={this.changeStartDate}
                                            placeholderText="Start date"
                                        />
                                        <div className="error">{this.state.startDateErr}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">End Date<span className="red">*</span></label>
                                    <div className="form-group">
                                        {/* <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedEndDate}
                                            minDate={new Date(selectedStartDate + 5 * 86400000)}
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => this.changeEndDate(date)}
                                            filterDate={(date) => date.getDay() === 5}
                                        /> */}
                                        <DatePicker
                                            name="endDate"
                                            className="web-font form-control form-bg"
                                            dateFormat="dd/MM/yyyy"
                                            selected={selectedEndDate}
                                            // minDate={new Date(Date.now() + 5 * 86400000)}
                                            maxDate={this.state.campaignPaymentType === "Paid" ? maxEndDate : maxFreeEndDate}
                                            onChange={this.state.campaignPaymentType === "Free" ? null : (date) => this.changeEndDate(date)}
                                            // filterDate={(date) => date.getDay() === 5}
                                            placeholderText="End date"
                                        />
                                        <div className="error">{this.state.endDateErr}</div>
                                    </div>
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="poll-date">No.of Views (in duration)<span className="red">*</span></label>
                                    {this.state.campaignPaymentType === "Paid" ? (
                                        <div >
                                            <select
                                                name="views"
                                                className="web-font form-control form-bg"
                                                value={this.state.views}
                                                onChange={this.handleViewChange}
                                            >
                                                {viewsData.map(option => (
                                                    <option key={option.id} value={option.views}>
                                                        {option.views}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="number"
                                                name="views"
                                                className="web-font form-control form-bg"
                                                value={this.state.campaignPaymentType === "Free" ? 100 : ""}
                                                disabled
                                            />
                                        </div>

                                    )}


                                </div>
                                <div className="col-md-6">
                                    <label className="poll-date">No.of Clicks (in duration)<span className="red">*</span></label>
                                    {this.state.campaignPaymentType === "Paid" ? (
                                        <div>
                                            <select
                                                name="clicks"
                                                className="web-font form-control form-bg"
                                                value={this.state.clicks}
                                                onChange={this.handleClickChange}
                                            >
                                                {clicksData.map(option => (
                                                    <option key={option.id} value={option.clicks}>
                                                        {option.clicks}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="number"
                                                name="clicks"
                                                className="web-font form-control form-bg"
                                                value={this.state.campaignPaymentType === "Free" ? 0 : ""}
                                                disabled
                                            />
                                        </div>
                                    )}
                                </div>
                            </div><br />
                            {/* <div className="row">
                                    <div className="col-md-6">
                                        <input type="text"
                                            name="viewAmount"
                                            className="form-control web-font"
                                            value={`Rs.` + ((this.state.campaignPaymentType == "Free" ? 100 : this.state.views) * (this.state.paymentType === "Free" ? 3 : 12)) + "/mon"}
                                            disabled={true} />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text"
                                            name="clickAmount"
                                            className="form-control web-font"
                                            value={`Rs.` + ((this.state.campaignPaymentType == "Free" ? 0 : this.state.clicks) * 25) + "/click"}
                                            style={{ marginRight: '5px' }}
                                            disabled={true} />
                                    </div>
                                </div><br /> */}

                            <input type="text"
                                name="totalAmount"
                                className="form-control web-font"
                                value={`Rs.${this.state.totalAmount}`}
                                // value={`Rs.${this.state.campaignPaymentType === "Free" ? '0' : (250 + (this.state.clicks > 0 ? parseInt(this.state.clicks) * 25 : 0))}`}

                                // value={`Rs.` + ((this.state.paymentType == "Free" ? 100 : this.state.views) * (this.state.paymentType === "Free" ? 3 : 12)) + "/mon"}
                                disabled={true} /><br />

                        </Form><br />
                    </Card><br />
                    <Card style={{ width: "80%" }}><br />
                        <div style={{ marginLeft: "1.2rem", width: "75%" }}>
                            <h5 >Advertiser's Details</h5><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Advertiser Name<span className="red">*</span></label>
                                    <input type="text"
                                        className="web-font form-control"
                                        name="advertiserName"
                                        value={this.state.advertiserName}
                                        readOnly
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Advertiser Designation<span className="red">*</span></label>
                                    <input type="text"
                                        className="web-font form-control"
                                        name="designation"
                                        value={this.state.designation}
                                        readOnly
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Email<span className="red">*</span></label>
                                    <input type="email"
                                        className="web-font form-control"
                                        name="advertiserEmail"
                                        readOnly
                                        value={this.state.advertiserEmail}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Mobile Number<span className="red">*</span></label>
                                    <input type="number"
                                        className="web-font form-control"
                                        name="advertiserNumber"
                                        readOnly
                                        value={this.state.advertiserNumber}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div><br />
                    </Card><br />
                    {/* <Link to="/advertisement"><div className="cancel-right">
                    <Button variant="default" onClick={this.publishAdvertisement} className="sendreview-btn mt-1 ml-3">Submit </Button>
                        <Button variant="default" className="cancelreview-btn mt-1 ml-4">Cancel</Button>
                    </div></Link> */}
                    {((this.state.campaignName !== "") && (this.state.image !== "") && (this.state.type !== "") && (this.state.campaignPaymentType !== "") && (this.state.campaignUrl !== "") && (this.state.startDate !== "") && (this.state.endDate !== "") && (this.state.description !== "") && (this.state.advertiserName !== "") && (this.state.advertiserEmail !== "") && (this.state.advertiserNumber !== "")) ?
                        <div className="cancel-right"><Button variant="default" onClick={this.publishAdvertisement} className="sendreview-btn mt-1 ml-4">Submit</Button><Link to="/advertisement"> <Button variant="default" className="cancelreview-btn mt-1 ml-4">Cancel</Button></Link></div> :
                        <div className="cancel-right"><Button variant="default" disabled={true} className="sendreview-btn mt-1 ml-4">Submit</Button><Link to="/advertisement"><Button variant="default" className="cancelreview-btn mt-1 ml-4">Cancel</Button></Link></div>}
                </div>
            </div>
        );
    };
}

