import React from 'react';
import { Link, Route, NavLink, Router } from 'react-router-dom';

export default function AllLinks() {
    return (
        <div>
            <div style={{ display: "flex" }}>
                <NavLink to="/history/usersreach" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Users</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/history/articles" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Articles</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/history/polls" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Polls</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/history/sceemlorereach" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Sceemlore </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/history/advertisements" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Advts</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/history/referrals" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Referrals </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/history/opportunities" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Oprts</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/history/q&a" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Q&A </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/history/interests" activeClassName="history-active" className="Link" >
                    <div className="history-Text">EOI</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/history/blocked" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Blk Articles</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                {/* <NavLink to="/history/claimhistory" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Claims</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                <NavLink to="/history/reviewers" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Reviewers</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/history/gamesreach" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Games</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/history/smereach" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Sme</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/history/redemptions" activeClassName="history-active" className="Link" >
                    <div className="history-Text">Redeems</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
            </div >
        </div>
    )
}
