
import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import { Tabs, Tab } from "react-bootstrap";
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import AllLinks from "./AllLinks";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const SmeReach = () => {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')));
    const [smeReach, setSmeReach] = useState([]);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [allOfferings, setAllOfferings] = useState([]);
    const [commercialPlans, setCommercialPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [key, setKey] = useState('smeReach');
    const [smeData, setSmeData] = useState([
        {
            name: "Teena",
            months: [
                { monthName: "Jan", bookings: 4, totalAmount: 2000 },
                { monthName: "Feb", bookings: 1, totalAmount: 500 },
                { monthName: "Mar", bookings: 5, totalAmount: 2500 },
                { monthName: "Apr", bookings: 5, totalAmount: 2500 },
                { monthName: "May", bookings: 2, totalAmount: 1000 },
                { monthName: "Jun", bookings: 0, totalAmount: 0 },
                { monthName: "Jul", bookings: 0, totalAmount: 0 },
                { monthName: "Aug", bookings: 0, totalAmount: 0 },
                { monthName: "Sep", bookings: 0, totalAmount: 0 },
                { monthName: "Oct", bookings: 0, totalAmount: 0 },
                { monthName: "Nov", bookings: 0, totalAmount: 0 },
                { monthName: "Dec", bookings: 0, totalAmount: 0 },
            ]
        },
    ]
    );

    // months: [
    //     Jan: { bookings: 4, totalAmount: 2000 },
    //     Feb: { bookings: 1, totalAmount: 500 },
    //     Mar: { bookings: 5, totalAmount: 2500 },
    //     Apr: { bookings: 5, totalAmount: 2500 },
    //     May: { bookings: 2, totalAmount: 1000 },
    //     Jun: { bookings: 0, totalAmount: 0 },
    //     Jul: { bookings: 0, totalAmount: 0 },
    //     Aug: { bookings: 0, totalAmount: 0 },
    //     Sep: { bookings: 0, totalAmount: 0 },
    //     Oct: { bookings: 0, totalAmount: 0 },
    //     Nov: { bookings: 0, totalAmount: 0 },
    //     Dec: { bookings: 0, totalAmount: 0 },
    // ]
    useEffect(() => {
        getAllSmeOfferings();
        getAllDays();
        getAllTimeSlots();
        getAllCommercialPlans();
        getAllSme();
        getMonthlySmeBookings();
    }, []);

    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(response => {
                console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings);
                }
            })
            .catch(error => console.log("Error"));
    };

    const getAllDays = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllDays', { headers: headers })
            .then(response => {
                console.log(response);
                if (response.data.status === true) {
                    // setAllDays(response.data.days);
                }
            })
            .catch(error => console.log("Error"));
    };

    const getAllTimeSlots = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllTimeSlots', { headers: headers })
            .then(response => {
                console.log(response);
                if (response.data.status === true) {
                    setAllTimeSlots(response.data.timeSlots);
                }
            })
            .catch(error => console.log("Error"));
    };

    const getAllCommercialPlans = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllCommercialPlans', { headers: headers })
            .then(response => {
                console.log(response);
                if (response.data.status === true) {
                    setCommercialPlans(response.data.commercialPlans);
                }
            })
            .catch(error => console.log("Error"));
    };

    const getAllSme = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllSme', { headers: headers })
            .then(response => {
                console.log(response);
                if (response.data.status === true) {
                    setSmeReach(response.data.data);
                    setIsLoading(false);
                }
            })
            .catch(error => console.log("Error"));
    };
    const getMonthlySmeBookings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getMonthlySmeBookings', { headers: headers })
            .then(response => {
                console.log(response);
                if (response.data.status === true) {
                    //  formatSmeData(response.data.data)
                    // setSmeData(formatSmeData(response.data.data))
                    console.log("sme", formatSmeData(response.data.data))
                    setIsLoading(false);
                }
            })
            .catch(error => console.log("Error"));
    };
    const formatSmeData = (smeData) => {
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const formattedData = smeData.map((user) => {
            const formattedMonths = monthNames.map((monthName) => {
                const existingMonth = user.months.find((month) => month.monthName === monthName);
                if (existingMonth) {
                    return existingMonth;
                } else {
                    return { monthName: monthName, bookings: 0, totalAmount: 0 };
                }
            });
            return { ...user, months: formattedMonths };
        });
        return formattedData;
    };

    const getTimeSlot = (slotId) => {
        const item = allTimeSlots.find(txt => txt.id === slotId);
        return item ? item.time : "";
    };

    const getPlan = (planId) => {
        const item = commercialPlans.find(txt => txt.id === planId);
        return item ? item.plan : "";
    };

    const getOfferings = (off) => {
        const items = allOfferings
            .filter(data => off.includes(data.id))
            .map(data => data.text);
        const result = items.join(', ');
        console.log("result", result);
        return result;
    };

    const formatRatingNumber = (number) => {
        const roundedNumber = Math.round(number * 100) / 100;
        const decimalPart = roundedNumber - Math.floor(roundedNumber);

        if (decimalPart === 0) {
            return Math.floor(roundedNumber);
        } else {
            return roundedNumber.toFixed(1);
        }
    };
    const renderTable = () => {
        return (
            <div className="mt-3 tableFixHead">
                <table className="table-striped">
                    <thead style={{ background: "#eee" }}>
                        <tr>
                            <th scope="col" className="th-heading">Sme Name</th>
                            {smeData.map((data, i) => (
                                <React.Fragment key={i}>
                                    <th scope="col" className="th-heading">{data.monthName}</th>
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {smeData.map((data, i) => (
                            <tr key={i} className={i % 2 === 0 ? "" : "green"}>
                                <td className="td-text">{data.name}</td>
                                {data.months.map((month, index) => (
                                    <React.Fragment key={index}>
                                        <td className="td-text">{month.bookings}, {month.totalAmount}</td>
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                <AllLinks />
                {/* <div className="mt-3 active-add-h-text"> Sme Reach</div> */}
                <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 mt-4 inCompleteUser" style={{ color: "#ff942c" }}
                >
                    <Tab eventKey="smeReach" title="Sme Reach">
                        {smeReach.length === 0 ? (
                            <center><h4>No Sme's.</h4></center>
                        ) : (
                            <div class="mt-3 tableFixHead" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr  >
                                            <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Sme Name</th>
                                            <th scope="col" className="th-heading" style={{ width: "20%" }}>Offerings</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Time Slot</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Commercial</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Appointments</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Assessment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {smeReach.map((data, i) => (
                                            <tr className={i / 2 === 0 ? "" : "green"}>
                                                <td className="td-text">{i + 1}</td>
                                                <td className="td-text">{data.name}</td>
                                                <td className="td-text">{getOfferings(data.offering)}</td>
                                                <td className="td-text">{getTimeSlot(data.time)}</td>
                                                <td className="td-text">{getPlan(data.commercial)}</td>
                                                <td className="td-text">{data.bookings}</td>
                                                <td className="td-text">{formatRatingNumber(data.avg_assessment)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </Tab>
                    <Tab eventKey="smeTable" title="Sme Data">
                        {/* {smeData.length === 0 ? (
                        <center><h4>No Sme's.</h4></center>
                    ) : (
                        renderTable()
                    )} */}
                        {smeData.length === 0 ? (
                            <center><h4>No Sme's.</h4></center>
                        ) : (
                            <div className="mt-3 tableFixHead">
                                <table className="table-striped">
                                    <thead style={{ background: "#eee" }}>
                                        <tr>
                                            <th scope="col" className="th-heading">Sme Name</th>
                                            {smeData[0].months.map((month, i) => (
                                                <th key={i} scope="col" className="th-heading"><center>{month.monthName}</center></th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {smeData.map((sme, index) => (
                                            <tr key={index} className={index % 2 === 0 ? "" : "green"}>
                                                <td className="td-text"><b>{sme.name}</b> (Appointments)<br /> (Total Amount)</td>
                                                {sme.months.map((monthData, monthIndex) => (
                                                    <td key={monthIndex} className="td-text"><center>{monthData.bookings} <br />{monthData.totalAmount}</center></td>

                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </Tab>
                </Tabs>
            </div>
        </div >
    );
}

export default SmeReach;