import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Redirect, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import createpoll from '../images/createpoll.svg';
import savedpolls from '../images/savedpolls.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import AllLinks from "./AllLinks";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class SceemloreReach extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            cardDetails: [],
            isLoading: true
        }
    }
    componentDidMount() {
        this.getCardManagementDetail();
    }
    getCardManagementDetail = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getAllDetailOfCardByAdmin', { headers: headers })
            .then(function (response) {
                //   console.log("card", response);
                if (response.data.status == true) {
                    // console.log("getCardManagementDetail");
                    self.setState({ cardDetails: response.data.getAllDetailOfCardByAdmin, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, cardDetails } = this.state;
        const created = cardDetails.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    <AllLinks />
                    <div className="mt-3 active-add-h-text">Sceemlore Reach (Last 90 days)</div>
                    {cardDetails.length === 0 ? <center><h4>No Sceemlore</h4></center> :
                        <div class="mt-3 tableFixHead">
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr >
                                        <th scope="col" className="th-heading" style={{ width: "16%" }}>Learning Title</th>
                                        <th scope="col" className="th-heading" style={{ width: "12%" }}>Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "12%" }}>No.of Cards</th>
                                        <th scope="col" className="th-heading" style={{ width: "25%" }}>Topic</th>
                                        <th scope="col" className="th-heading" style={{ width: "14%" }}>Created On</th>
                                        <th scope="col" className="th-heading" style={{ width: "7%" }}>Rating</th>
                                        <th scope="col" className="th-heading" style={{ width: "7%" }}>Learners</th>
                                        <th scope="col" className="th-heading" style={{ width: "7%" }}>Version</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cardDetails.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text" key={i} >{data.title}</td>
                                            <td className="td-text" key={i} >{data.name}</td>
                                            <td className="td-text" key={i} >{data.cards}</td>
                                            <td className="td-text" key={i} >{data.topicName}</td>
                                            <td className="td-text" key={i} >{created[i]}</td>
                                            <td className="td-text" key={i}>{data.avgrating}</td>
                                            <td className="td-text" key={i}>{data.learners}</td>
                                            <td className="td-text" key={i}>{data.version}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                </div>
            </div >
        );
    };
}

