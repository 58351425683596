import React, { Component } from "react";
import { Button, Form, Table, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import moment from "moment-timezone";
import '../Advertisements/Advertisement.css';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class AllOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            orders: [],
            redirect: false,
            isLoading: true
        }
    }
    componentDidMount() {
        this.getAllOrders();
    }

    getAllOrders = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllTshirtOrders', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        orders: response.data.orders,
                        isLoading: false
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, orders } = this.state
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >

                    <div >
                        <div className="mt-3 active-add-h-text">All Orders</div>
                        {orders.length !== 0 ?
                            <div class="mt-3 tableFixHead" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr>
                                            <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                            <th scope="col" className="th-heading" style={{ width: "10%" }}>Order Id</th>
                                            <th scope="col" className="th-heading" style={{ width: "10%" }}>Name</th>
                                            <th scope="col" className="th-heading" style={{ width: "10%" }}>Size</th>
                                            <th scope="col" className="th-heading" style={{ width: "10%" }}>Color</th>
                                            <th scope="col" className="th-heading" style={{ width: "10%" }}>Price</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Order Status</th>
                                            <th scope="col" className="th-heading" style={{ width: "12%" }}>Created At</th>
                                            <th scope="col" className="th-heading" style={{ width: "12%" }}>Delivered On</th>
                                            <th ></th>
                                            <th ></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {orders.map((data, i) => (
                                            <tr className={i / 2 === 0 ? "" : "green"}>
                                                <td className="td-text" >&nbsp;&nbsp;{i + 1}</td>
                                                <td className="td-text">{data.orderId}</td>
                                                <td className="td-text">{data.name ? data.name : data.orderName}</td>
                                                <td className="td-text">{data.size}</td>
                                                <td className="td-text">{data.color}</td>
                                                <td className="td-text">{data.price}</td>
                                                <td className="td-text">{data.orderStatus}</td>
                                                <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                                                <td className="td-text">{data.deliveryDate ? moment(data.deliveryDate).format("DD-MM-YYYY") : ""}</td>
                                                <td className="cursor"><Link to={`/order/vieworder/${data.id}`} className="poll-link"> <i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                                                <td className="cursor" ><Link to={`/order/editorder/${data.id}`} className="poll-link"> {data.orderStatus === "Delivered" ? "" : <i class="fa fa-pencil left" aria-hidden="true"></i>}</Link> </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div> : <center><h4>No Orders</h4></center>
                        }
                    </div>


                </div>
            </div >
        );
    };
}

