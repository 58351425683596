import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import addimage from '../images/addimage.svg'
import './Advertisement.css';
import { Edit } from './EditJson';
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import fileupload from '../images/file-upload-svgrepo-com.svg';
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ViewCampaign extends Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            imageUrl: "",
            image: "",
            campaignName: "",
            designation: "",
            type: "",
            timingType: "",
            clicks: "",
            views: "",
            amount: '',
            description: "",
            startDate: "",
            endDate: "",
            campaignUrl: "",
            campaignPaymentType: "",
            advertiserName: "",
            advertiserEmail: "",
            advertiserNumber: "",
            advType: "",
            viewAmount: "",
            clickAmount: "",
            bannerImg: "",
            advTypes: [],
            selectedAdvType: '',
            location: "",
            typesOfAdvertisement: "",
            url: "",
            paidOrFree: "",
            tireToAdvertise: "",
            payment: "",
            adv: Edit[0],
            publish: true,
            save: false,
            isLoading: true

        }
    }
    onChange = (e) => {
        this.setState({
            adv: Object.assign({}, this.state.adv[0], { [e.target.name]: e.target.value })
        })
    }
    fileHandler = (e) => {
        this.setState({ image: (e.target.files[0]) })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        //   console.log(this.state)
    }
    changeStartDate(date) {
        this.setState({ startDate: date })
    }
    changeEndDate(date) {
        this.setState({ endDate: date })
    }
    componentDidMount = () => {
        this.getAllAdvertismentTypes();
        this.getCampaignById();
    }
    getAllAdvertismentTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllAdvertismentTypes', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    self.setState({ advTypes: response.data.advTypes })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getCampaignById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + `user/getCampaignById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    //   console.log("success!!");
                    self.setState({
                        campaignName: response.data.campaign.campaignName,
                        campaignPaymentType: response.data.campaign.campaignPaymentType,
                        campaignUrl: response.data.campaign.campaignUrl,
                        description: response.data.campaign.description,
                        startDate: response.data.campaign.startDate,
                        endDate: response.data.campaign.endDate,
                        image: response.data.campaign.imageUrl,
                        type: response.data.campaign.type,
                        advertiserName: response.data.campaign.advertiserName,
                        advertiserEmail: response.data.campaign.advertiserEmail,
                        advertiserNumber: response.data.campaign.advertiserNumber,
                        designation: response.data.campaign.designation,
                        advType: response.data.campaign.advType,
                        bannerImg: response.data.campaign.bannerImg,
                        selectedAdvType: response.data.campaign.advType,
                        timingType: response.data.campaign.timingType,
                        clicks: response.data.campaign.clicks,
                        clickAmount: response.data.campaign.clickAmount,
                        viewAmount: response.data.campaign.viewAmount,
                        views: response.data.campaign.views,
                        amount: response.data.campaign.amount,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { startDate, endDate, isLoading, advTypes, image, imgRef, bannerImg } = this.state
        var a = new Date(startDate)
        var start = parseInt(a.getMonth() + 1) + "-" + a.getDate() + "-" + a.getFullYear();
        var b = new Date(endDate)
        var endD = parseInt(b.getMonth() + 1) + "-" + b.getDate() + "-" + b.getFullYear();
        const getText = (id) => {
            const item = advTypes.find(txt => txt.id === id);
            return item ? item.advType : "";
        }
        const selectedStartDate = new Date(start)
        const selectedEndDate = new Date(endD)
        //  console.log("start", startDate)

        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <Card style={{ width: "80%" }}><br />
                        <h5 style={{ marginLeft: "1.2rem" }}>View Campaign</h5><br />
                        <Form style={{ marginLeft: "1.2rem", width: "70%" }} onSubmit={this.handleSubmit}>
                            <div class="row" >
                                <div class="col-md-9">
                                    <label className="ml-1 mt-1 poll-date" style={{ fontSize: "14px" }}>Product/Promotion Banner<span className="red">*</span></label><br />
                                    <div style={{ height: "120px", textAlign: "center", objectFit: "contain", border: "1px solid black", borderRadius: "10px" }}>
                                        <label htmlFor="upload-button-banner">
                                            {bannerImg != "" ? (
                                                <img src={bannerImg ? bannerImg : ""} style={{ marginTop: "4px", marginBottom: "4px", width: "240px", height: "110px" }} />
                                            ) : (
                                                <>
                                                    <img src={fileupload} style={{ marginTop: "2.5rem", cursor: "pointer", width: "29px" }} />

                                                </>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            id="upload-button-banner"
                                            name="bannerImg"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label className="poll-date">Upload Logo<span className="red">*</span></label><br />
                                    <div style={{ height: "120px", textAlign: "center", objectFit: "contain", border: "1px solid black", borderRadius: "10px" }}>
                                        <label htmlFor="upload-button-logo">
                                            {image != "" ? (
                                                <img src={image} style={{ marginTop: "5px", marginBottom: "5px", width: "100px", height: "100px" }} />
                                            ) : (
                                                <>
                                                    <img src={fileupload} style={{ marginTop: "2.5rem", cursor: "pointer", width: "29px" }} />

                                                </>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            id="upload-button-logo"
                                            name="image"
                                        />
                                    </div>
                                </div>
                            </div><br />
                            <label className="poll-date">Campaign Name<span className="red">*</span></label>
                            <input type="text"
                                className="web-font form-control"
                                aria-describedby="text"
                                name="campaignName"
                                value={this.state.campaignName}
                                readOnly
                                onChange={this.onChange}
                            /><br />
                            <label className="poll-date">URL<span className="red">*</span></label>
                            <div className="form-group">
                                <input type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    placeholder="Campaign Url"
                                    name="campaignUrl"
                                    readOnly
                                />
                            </div>
                            <br />

                            <label className="poll-date">Description<span className="red">*</span></label>
                            <div className="form-group">
                                <textarea type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    rows="5"
                                    name="description"
                                    readOnly
                                    value={this.state.description}
                                    onChange={this.onChange}
                                />
                            </div><br />
                            <div class="row" >
                                <div class="col-md-6">
                                    <label className="poll-date">Business Type<span className="red">*</span></label>
                                    <select value={this.state.type} readOnly name="typeOfAdvertisement" onChange={this.onChange} class="web-font form-control">
                                        <option value="">Select</option>
                                        <option value="Commercial">Commercial</option>
                                        <option value="Non-commercial">Non-Commercial</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Adv Type<span className="red">*</span></label>
                                    <select name="advType" readOnly className="web-font form-control">
                                        <option value={this.state.selectedAdvType ? this.state.selectedAdvType : ""}>{this.state.selectedAdvType ? getText(this.state.selectedAdvType) : "Select adv type"}</option>
                                        {this.state.advTypes.map(data => (
                                            <option key={data.id} value={data.id}>{data.advType}</option>
                                        ))}
                                    </select>
                                </div>
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Paid / Free</label>
                                    <select readOnly value={this.state.campaignPaymentType} name="paidOrFree" onChange={this.onChange} class="web-font form-control">
                                        <option value="">Select</option>
                                        <option value="Paid">Paid</option>
                                        <option value="Free">Free</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Timing Type<span className="red">*</span></label>
                                    <div className="form-group">
                                        <select
                                            name="timingType"
                                            className="form-control web-font"
                                            value={this.state.timingType}
                                            readOnly
                                        >
                                            <option value="">Select Timing type</option>
                                            <option value={"Omni time"}>Omni time</option>
                                            <option value={"Prime time"}>Prime time</option>
                                        </select>
                                    </div>
                                </div>
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Start Date<span className="red">*</span></label>
                                    <div className="form-group">
                                        <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedStartDate}
                                            minDate={new Date(Date.now())}
                                            dateFormat="dd-MM-yyyy"
                                            readOnly
                                            onChange={(date) => this.changeStartDate(date)}
                                            filterDate={(date) => date.getDay() === 1}
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">End Date<span className="red">*</span></label>
                                    <div className="form-group">
                                        <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedEndDate}
                                            readOnly
                                            minDate={new Date(selectedStartDate + 5 * 86400000)}
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => this.changeEndDate(date)}
                                            filterDate={(date) => date.getDay() === 5}
                                        />
                                    </div>
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="poll-date">No.of Views (in duration)<span className="red">*</span></label>
                                    <input type="text"
                                        name="views"
                                        className="form-control web-font"
                                        value={this.state.campaignPaymentType == "Free" ? 100 : this.state.views}
                                        placeholder="No.of Views"
                                        style={{ marginRight: '5px' }}
                                        disabled={this.state.campaignPaymentType == "Free" ? true : false}
                                        readOnly />


                                </div>
                                <div className="col-md-6">
                                    <label className="poll-date">No.of Clicks (in duration)<span className="red">*</span></label>
                                    <input type="text"
                                        name="clicks"
                                        className="form-control web-font"
                                        value={this.state.campaignPaymentType == "Free" ? 0 : this.state.clicks}
                                        disabled={this.state.campaignPaymentType == "Free" ? true : false}
                                        placeholder="No.of Clicks"
                                        readOnly />
                                </div>
                            </div><br />
                            <input type="text"
                                name="amount"
                                className="form-control web-font"
                                value={`Rs.${this.state.campaignPaymentType === "Free" ? '0' : (250 + (this.state.clicks > 0 ? parseInt(this.state.clicks) * 25 : 0))}`}

                                // value={`Rs.` + ((this.state.paymentType == "Free" ? 100 : this.state.views) * (this.state.paymentType === "Free" ? 3 : 12)) + "/mon"}
                                disabled={true} /><br />
                            {/* <div className="row">
                                <div className="col-md-6">
                                    <input type="text"
                                        name="viewAmount"
                                        className="form-control web-font"
                                        value={`Rs.` + this.state.viewAmount + "/mon"}
                                        disabled={true} />
                                </div>
                                <div className="col-md-6">
                                    <input type="text"
                                        name="clickAmount"
                                        className="form-control web-font"
                                        value={`Rs.` + this.state.clickAmount + "/click"}
                                        style={{ marginRight: '5px' }}
                                        disabled={true} />
                                </div>
                            </div><br /> */}

                        </Form><br />
                    </Card><br />
                    <Card style={{ width: "80%" }}><br />
                        <div style={{ marginLeft: "1.2rem", width: "75%" }}>
                            <h5 >Advertiser's Details</h5><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Advertiser Name<span className="red">*</span></label>
                                    <input type="text"
                                        className="web-font form-control"
                                        name="advertiserName"
                                        value={this.state.advertiserName}
                                        readOnly
                                        onChange={this.onChange}
                                        onBlur={this.handleBlur}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="poll-date">Advertiser Designation<span className="red">*</span></label>
                                    <input type="text"
                                        className="web-font form-control"
                                        name="designation"
                                        value={this.state.designation}
                                        readOnly
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div><br />

                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Email<span className="red">*</span></label>
                                    <input type="email"
                                        className="web-font form-control"
                                        name="advertiserEmail"
                                        readOnly
                                        value={this.state.advertiserEmail}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Mobile Number<span className="red">*</span></label>
                                    <input type="number"
                                        className="web-font form-control"
                                        name="advertiserNumber"
                                        readOnly
                                        value={this.state.advertiserNumber}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div><br />
                    </Card><br />
                    <Link to="/advertisement"><div className="cancel-right"><Button variant="default" className="cancelreview-btn mt-1 ml-4">Cancel</Button>
                    </div></Link>
                </div>
            </div >
        );
    };
}

