import React, { Component } from "react";
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import "../ReviewQueue/ReviewQueue.css";
import axios from "axios";
import moment from "moment"; // Correct import
import { config } from "../Config/Config";
import SimpleCrypto from "simple-crypto-js";
import AllLinks from "./AllLinks";

const key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;
const simpleCrypto = new SimpleCrypto(key);

export default class RedeemsReach extends Component {
  constructor(props) {
    super(props);
    this.state = {
        token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
      redeemsReach: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getClaimHistoryForAllUsers();
  }

  getClaimHistoryForAllUsers = () => {
    const { token } = this.state;
    if (!token) return;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      applicationType: "web",
    };

    axios.get(`${config.userUrl}user/getClaimHistoryForAllUsers`, { headers })
      .then((response) => {
        if (response.data.status) {
          this.setState({ redeemsReach: response.data.claimHistory, isLoading: false });
        } else {
          console.error("API response status false:", response.data.message);
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.error("Error fetching claim history:", error.message);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, redeemsReach } = this.state;

    if (isLoading) {
      return <div className="App"></div>;
    }

    return (
      <div>
        <NavBar />
        <Sidebar />
        <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
          <AllLinks />
          <div className="mt-3 active-add-h-text">Redeem History</div>
          {redeemsReach?.length === 0 ? (
            <center>
              <h4>No Redeem History.</h4>
            </center>
          ) : (
            <div className="mt-3 tableFixHead">
              <table className="table-striped">
                <thead style={{ background: "#eee" }}>
                  <tr>
                    <th scope="col" className="th-heading" style={{ width: "10%" }}>
                      S.No
                    </th>
                    <th scope="col" className="th-heading" style={{ width: "30%" }}>
                      Username
                    </th>
                    <th scope="col" className="th-heading" style={{ width: "20%" }}>
                      Purchases
                    </th>
                    <th scope="col" className="th-heading" style={{ width: "15%" }}>
                      Points
                    </th>
                    <th scope="col" className="th-heading" style={{ width: "25%" }}>
                      Redeemed Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {redeemsReach.map((data, i) => (
                    <tr key={i} className={i % 2 === 0 ? "" : "green"}>
                      <td className="td-text">{i + 1}</td>
                      <td className="td-text">{data.name || "N/A"}</td>
                      <td className="td-text">{data.relatedName || "N/A"}</td>
                      <td className="td-text">{data.rewardPoints || 0}</td>
                      <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}