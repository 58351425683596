import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import AllLinks from "./AllLinks";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class QAReach extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            quesAndAns: [],
            isLoading: true

        }
    }

    componentDidMount() {
        this.getQuestionAndAnswerDetails()
    }
    getQuestionAndAnswerDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getQuestionAndAnswerDetails', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status == true) {
                    self.setState({ quesAndAns: response.data.getQuestionAndAnswerDetails, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, quesAndAns } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }
        const updatedDate = quesAndAns.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    <AllLinks />
                    <div className="mt-3 active-add-h-text">Q&A Reach (Last 90 days)</div>
                    {quesAndAns.length === 0 ? <center><h4>No history.</h4></center> :
                        <div class="mt-3 tableFixHead" >
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr  >
                                        <th scope="col" className="th-heading" style={{ width: "25%" }}>Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "40%" }}>Question</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Raised on</th>
                                        <th scope="col" className="th-heading" style={{ width: "20%" }}>People Answered</th>
                                    </tr>
                                </thead>

                                <tbody>{quesAndAns.map((data, i) => (
                                    <tr className={i / 2 === 0 ? "" : "green"}>
                                        <td className="td-text">{data.name}</td>
                                        <td className="td-text">{data.question}</td>
                                        <td className="td-text">{updatedDate[i]}</td>
                                        <td className="td-text">{data.answerOfCount}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div >
        );
    };
}