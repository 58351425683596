import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import AllLinks from "./AllLinks";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class PollsReach extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            pollsReach: [],
            isLoading: true

        }
    }

    componentDidMount() {
        this.getPollsReach()
    }
    getPollsReach = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getPollDetails', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status == true) {
                    self.setState({ pollsReach: response.data.polls, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, pollsReach } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }
        const updatedDate = pollsReach.map((i, idx) => {
            var a = new Date(i.startDate)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        const updatedEnd = pollsReach.map((i, idx) => {
            var a = new Date(i.endDate)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    <AllLinks />
                    <div className="mt-3 active-add-h-text"> Poll Reach (Last 90 days)</div>
                    {pollsReach.length === 0 ? <center><h4>No Polls.</h4></center> :
                        <div class="mt-3 tableFixHead" >
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr  >
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Poll Question</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Topic</th>
                                        <th scope="col" className="th-heading" style={{ width: "12%" }}>Start Date</th>
                                        <th scope="col" className="th-heading" style={{ width: "12%" }}>End Date</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Option</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Published By</th>
                                        <th scope="col" className="th-heading" style={{ width: "8%" }}>Registered</th>
                                        <th scope="col" className="th-heading" style={{ width: "8%" }}>Unregistered</th>
                                    </tr>
                                </thead>

                                <tbody>{pollsReach.map((data, i) => (
                                    <tr className={i / 2 === 0 ? "" : "green"}>
                                        <td className="td-text">{data.pollQuestion}</td>
                                        <td className="td-text">{data.topicName.replace(/ *\([^]*\) */g, "")}</td>
                                        <td className="td-text">{updatedDate[i]}</td>
                                        <td className="td-text">{updatedEnd[i]}</td>
                                        <td className="td-text">{data.option === null ? "No User Answered" : data.option}</td>
                                        <td className="td-text">{data.name}</td>
                                        <td className="td-text">{data.peopleparticipated === null ? 0 : data.peopleparticipated}</td>
                                        <td className="td-text">{data.peopleparticipatedasunregistered === null ? 0 : data.peopleparticipatedasunregistered}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div >
        );
    };
}